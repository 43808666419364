<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" style="max-height: 90px;" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Centro Studi e Formazione FT è una scuola di formazione professionale  con docenti di estrazione aziendale in grado di offrire una formazione in linea con le richieste del mondo del lavoro
                    </p>
                    <ul class="social-link">
                        <li><a href="https://facebook.com/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="http://instagram.com/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>LINK</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>LOGIN</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Menù</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>DOVE SIAMO</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>via Gabriele Barrio, 14<br>87100 Cosenza</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+390984023884">Telefono: (+39) 0984-023884</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:info@centrostudieformazioneft.it">info@centrostudieformazioneft.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+393762143890">Lunedì-Venerdì<br>09:00 ÷ 18:00</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2024 Centro Studi e Formazione FT - realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a href="https://centrostudieformazioneft.it/privacy" target="_blank">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
